
.shopRegisterSet{
  padding-top: 38px;
}
::v-deep .select-address .el-form-item__content .el-col-7{
  display: flex;
}
::v-deep .el-input-number .el-input__inner{
  text-align: left;
}
.uploading-box{
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 16px;
    .left-icon {
      display: inline-block;
      width: 4px;
      height: 16px;
      background-color: #2DC079;
      margin-right: 6px;
      border-radius: 2px;
    }
  }
  .hot-goods {
    justify-content: flex-start;
    .distribution-goods {
      margin-left: 20px;
    }
  }
  //.content-item {
  //  .item {
  //    margin-bottom: 16px;
  //    .text {
  //      display: inline-block;
  //      width: 100px;
  //      text-align: right;
  //      font-size: 16px;
  //      color: #333333;
  //      margin-right: 10px;
  //    }
  //    .name-input {
  //      width: 360px;
  //      ::v-deep .el-input__inner {
  //        border-radius: 0;
  //        text-align: left;
  //      }
  //    }
  //    .practice-select {
  //      width: 360px;
  //      margin-right: 10px;
  //      ::v-deep .el-input__inner {
  //        border-radius: 0;
  //      }
  //    }
  //    .practice-cascader {
  //      width: 420px;
  //      ::v-deep .el-input__inner {
  //        border-radius: 0;
  //      }
  //    }
  //  }
  //  .has-sore {
  //    display: flex;
  //    .has-btn {
  //      display: flex;
  //      align-items: center;
  //      .name-input {
  //        border: 1px solid #DCDFE6;
  //        color: #606266;
  //        height: 40px;
  //        line-height: 40px;
  //        box-sizing: border-box;
  //        padding: 0 15px;
  //        margin-right: 16px;
  //      }
  //      .none-data {
  //        color: #b1b4c1d6;
  //      }
  //    }
  //  }
  //  .goods-put {
  //    .item {
  //      display: flex;
  //      align-items: center;
  //    }
  //  }
  //  .goods-title {
  //    .name-input {
  //      width: 474px;
  //    }
  //  }
  //}
  .upload-btn {
    position: relative;
    display: flex;
    align-items: center;
    .btn {
      width: 88px;
      height: 38px;
      background: #E7F6EF;
      border: 1px solid #2DC079;
      border-radius: 4px;
      line-height: 38px;
      text-align: center;
      color: #2DC079;
      cursor: pointer;
    }
    input {
      width: 88px;
      height: 40px;
      position: absolute;
      top: 0;
      cursor: pointer;
      opacity: 0;
    }
    .text {
      color: #999999;
      font-size: 14px;
      margin-left: 15px;
    }
  }
  .upload-file-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: center;
    .file-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 228px;
      height: 40px;
      padding: 10px;
      box-sizing: border-box;
      color: #333333;
      font-size: 14px;
      &:hover {
        cursor: pointer;
        background-color: #F5F7FA;
        .del-icon {
          display: block;
        }
        .finish-icon {
          display: none;
        }
      }
      .file-name {
        display: inline-block;
        width: 1%;
        flex: 1;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      i {
        color: #2461EF;
        margin: 0 10px 0 0;
      }
      .finish-icon {
        color: #2DC079;
        margin: 0 0 0 10px;
      }
      .del-icon {
        display: none;
        color: #FF0000;
        margin: 0 0 0 10px;
      }
    }
  }
}
::v-deep .el-form-item__error{
  //line-height: 10px;
}
.content-item {
  .content-title {
    position: relative;
    padding-left: 10px;
    margin-bottom: 22px;
    &:before {
      content: '';
      width: 4px;
      height: 16px;
      background-color: #2DC079;
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .item-content {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .item-title {
      width: 120px;
      text-align: right;
      margin-right: 10px;
    }
    .divide {
      width: 24px;
      height: 2px;
      background: #D2D2D2;
      margin: 0 18px;
    }
    .file-upload-btn {
      display: none;
    }
    .upload-text {
      margin-left: 11px;
      font-size: 14px;
      color: #999999;
    }
    .upload-file-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      align-items: center;
      .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        color: #333333;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          background-color: #F5F7FA;
          .del-icon {
            display: block;
          }
          .finish-icon {
            display: none;
          }
        }
        .file-name {
          display: inline-block;
          width: 1%;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .el-progress {
          margin-left: 20px;
          width: 160px;
        }
        i {
          color: #2461EF;
          margin: 0 10px 0 0;
        }
        .finish-icon {
          color: #2DC079;
          margin: 0 0 0 10px;
        }
        .del-icon {
          display: none;
          color: #FF0000;
          margin: 0 0 0 10px;
        }
      }
    }
  }

}
.green-dialog{
  position: relative;
  .close-icon{
    position: absolute;
    right: 26px;
    top: 18px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}
::v-deep .el-cascader{
  .is-disabled{
    .el-input__inner{
      padding-right: 0;
    }
    .el-input__suffix{
      display: none;
    }
  }
}
