
.add-task-dialog {
    .demo-ruleForm {
        .el-form-item {
            display: flex;
            margin-bottom: 15px;

            ::v-deep .el-form-item__label {
                margin-bottom: 0;
            }

            ::v-deep .el-form-item__content {
                width: 1%;
                flex: 1;
                margin-left: 10px !important;

                .el-input-number {
                    width: 100%;
                }

                .el-input-number.is-without-controls .el-input__inner {
                    text-align: left;
                }
            }
        }

        .upload-text {
            color: #999999;
        }

        .upload-box {
            display: flex;
            height: 80px;

            .upload-main {
                width: 80px;
                height: 80px;
                cursor: pointer;
                background: #EEEEEE;
                border-radius: 2px;
                color: #4D5AFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 32px;
                    color: #A0A0A0;
                }
            }

            .img-box {
                margin-left: 40px;
                position: relative;

                &:nth-child(3) {
                    margin-left: 20px;
                }

                .logo-box {
                    width: 80px;
                    height: 80px;
                }

                .el-icon-error {
                    width: 14px;
                    height: 14px;
                    color: #FF0000;
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 0;
                }
            }
        }

        .radio-box {
            .radio-item {
                display: flex;
                justify-content: space-between;
                margin-top: 16px;

                &:first-child {
                    margin-top: 0;
                }

                .divide {
                    width: 12px;
                    height: 2px;
                    background: #D2D2D2;
                    margin: 0 4px;
                }

                .el-radio {
                    width: 80px;
                    margin-top: 13px;

                    ::v-deep.el-radio__label {
                        font-size: 16px;
                    }
                }

                .item-right {
                    display: flex;
                    align-items: center;
                }

                .self-input {
                    &.max {
                        width: 200px;
                    }

                    &.medium {
                        width: 90px;
                    }

                    &.mini {
                        width: 80px;
                    }
                }

                .right-text {
                    font-size: 16px;
                    width: 32px;
                    margin: 0 4px;
                }
            }
        }
    }
}
