
.order-manage {
    .title-info {
        margin-top: 34px;

        .optimize-title-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .optimize-title01 {
                font-size: 16px;
                color: #333;
                position: relative;
                padding-left: 16px;

                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
        }
    }

    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }

    .goods-img-box {
        width: 80px;
        height: 70px;
        box-sizing: border-box;
        position: relative;

        .src-box {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 1px solid #EEEEEE;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .clear-good-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: -9px;
            right: -9px;
            z-index: 1;
            font-size: 18px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #ff0000;
            }
        }

    }

    .goods-uploader {
        ::v-deep.el-upload {
            width: 80px;
            height: 80px;
            background: #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;

            .goods-uploader-icon {
                font-size: 32px;
                color: #A0A0A0;
            }
        }
    }

    .null-data {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin-top: 20px;

        img {
            width: 80px;
        }
    }

    .dialog-footer {
        text-align: center;
        margin-top: 20px;
    }

}
::v-deep .el-table .cell{
    .imgBox{
        width: 100%;
        // height: 60px;
        display: flex !important;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;
        .goods-img{
          
            position: relative;
           .src-box-evimg{
            // border: 1px solid #DCDFE6 ;
             border-radius: 2px;
              width: 40px; 
              height: 40px; 
              display: flex; 
             justify-content: center; 
            align-items: center;
            margin-top: 2px;
           }
        
           .clear-good-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            right: -4px;
            z-index: 1;
            font-size: 12px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                color: #ff0000;
            }
        }
        }
        .src-box-evimg{
            border: 1px solid #DCDFE6 ;
             border-radius: 2px;
              width: 40px; 
              height: 40px; 
              display: flex; 
             justify-content: center; 
            align-items: center;
           }
        
    }
}
.evimg{
    display: block;
    width: 40px;
    height: 40px;
}
::v-deep .el-checkbox__label {
    position: absolute;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
