
.line-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .left {
        width: 100px;
        margin-right: 10px;
        color: #333;
        font-size: 16px;
        text-align: right;
    }
}
