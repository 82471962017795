
.el-form-item {
    display: flex;
    margin-bottom: 15px;

    ::v-deep .el-form-item__label {
        margin-bottom: 0;
    }

    ::v-deep .el-form-item__content {
        width: 1%;
        flex: 1;
        margin-left: 10px !important;

        .el-input-number {
            width: 100%;
        }

        .el-input-number.is-without-controls .el-input__inner {
            text-align: left;
        }

        .el-form-item__error {
            margin-top: 5px;
        }
    }
}

.radio-box {
    ::v-deep .el-radio__label {
        font-size: 16px;
        color: #333333;
    }

    .radio-item {
        .radio-text {
            font-size: 12px;
            color: #999999;
            line-height: 14px;
        }

        &:first-child {
            margin-bottom: 18px;
        }
    }
}

.input-describe {
    font-size: 12px;
    color: #999999;
    height: 10px;
    line-height: 24px;
}
