
.inventory-management{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    .title-info {
        margin-top: 34px;
        .optimize-title {
            font-size: 16px;
            color: #333;
            position: relative;
            padding: 0 0 0 16px;
            margin-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 16px;
                background: #2DC079;
                left: 0;
                top: 4px;
            }
        }
        .optimize-title-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .optimize-title01 {
                font-size: 16px;
                color: #333;
                position: relative;
                padding-left: 16px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
        }
        .item {
            margin-bottom: 16px;
            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }
        }
        .goods-table{
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .goods-table-header{
                padding: 0 30px;
                display: flex;
                background: #EEEEEE;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;
                span{
                    color: #666666;
                    font-size: 16px;
                }
                .table-header-item{
                    text-align: center;
                    flex: 1;
                    width: 1%;
                    &:first-of-type{
                        text-align: left;
                    }
                }
            }
            .goods-table-body{
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .table-body-item{
                    display: flex;
                    flex-direction: column;
                    .body-item-top{
                        padding: 0 30px;
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #EEEEEE;
                        .item-top-item, .item-bottom-item{
                            width: 1%;
                            flex: 1;
                            height: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            ::v-deep .el-date-editor{
                                .el-input__inner{
                                    padding: 0 10px;
                                }
                                .el-input__prefix{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .emil-num{
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }
    .dialog-footer {
        margin-top: 20px;
        text-align: center;
    }
}
