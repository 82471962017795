
.line-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .left {
        width: 66px;
        margin-right: 10px;
        color: #333;
        font-size: 16px;
        text-align: right;
    }
}
.second-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: '';
        width: 4px;
        height: 16px;
        background: #2DC079;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}
.good-box {
    height: 285px;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .good-checkbox-group {
        height: 100%;
        border: 1px solid #E5E5E5;
        padding: 0 20px 20px 0;
        box-sizing: border-box;
        .el-checkbox {
            margin: 20px 0 0 20px;
            width: calc(33.333333% - 20px);
            ::v-deep {
                .el-checkbox__label {
                    position: absolute;
                    width: 158px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
