
.goods-text-content {
    margin: 20px 0;
    .score-item {
        justify-content: flex-start !important;
        flex-direction: inherit !important;
        align-items: center;
        font-size: 16px;
        color: #333333;
        .title {
            margin-bottom: 0 !important;
            margin-right: 15px;
        }
        .name-input {
            width: 120px;
            margin-right: 10px;
            ::v-deep .el-input__inner {
                border-radius: 0;
                text-align: left;
            }
        }
    }
    .goods-item {
        display: flex;
        flex-direction: column;
        .title {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 96px;
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 4px;
                height: 16px;
                background-color: #2DC079;
                margin-right: 6px;
                border-radius: 2px;
            }
        }
        .content-item {
            .item {
                margin-bottom: 16px;
                .text {
                    display: inline-block;
                    width: 100px;
                    text-align: right;
                    font-size: 16px;
                    color: #333333;
                    margin-right: 10px;
                }
                .name-input {
                    width: 360px;
                    ::v-deep .el-input__inner {
                        border-radius: 0;
                        text-align: left;
                    }
                }
            }
        }
        .upload-btn {
            position: relative;
            display: flex;
            align-items: center;
            .btn {
                width: 88px;
                height: 38px;
                background: #E7F6EF;
                border: 1px solid #2DC079;
                border-radius: 4px;
                line-height: 38px;
                text-align: center;
                color: #2DC079;
            }
            input {
                width: 88px;
                height: 40px;
                position: absolute;
                top: 0;
                cursor: pointer;
                opacity: 0;
            }
            .text {
                color: #999999;
                font-size: 14px;
                margin-left: 15px;
            }
        }
    }
}
.upload-file-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        color: #333333;
        font-size: 14px;
        margin: 0 20px 15px 0;

        &:hover {
            cursor: pointer;
            background-color: #F5F7FA;

            .del-icon {
                display: block;
            }

            .finish-icon {
                display: none;
            }
        }

        .file-name {
            display: inline-block;
            width: 1%;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-progress {
            margin-left: 20px;
            width: 160px;
        }

        i {
            color: #2461EF;
            margin: 0 10px 0 0;
        }

        .finish-icon {
            color: #2DC079;
            margin: 0 0 0 10px;
        }

        .del-icon {
            display: none;
            color: #FF0000;
            margin: 0 0 0 10px;
        }
    }
}
.file-upload-btn {
    display: none;
}
.upload-text {
    font-size: 14px;
    color: #999;
    margin-left: 10px;
}
.assign-goods-dialog {
    .assign-search {
        margin-bottom: 15px;
        .practice-cascader {
            width: 273px;
            margin-left: 10px;
            ::v-deep .el-input__inner {
                border-radius: 2px;
            }
        }
    }
    .upload-file-content {
        height: 287px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .goods-checkbox-group {
            position: relative;
            height: 100%;
            padding: 12px;
            box-sizing: border-box;
            .goods-checkbox {
                width: 172px;
                padding-bottom: 10px;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                ::v-deep .el-checkbox__label {
                    position: absolute;
                    width: 158px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
            }

        }
    }
    .class-content {
        height: 287px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        .class-cascader {
            height: 100%;
            border: none;
        }
    }
    ::v-deep .el-dialog__footer {
        text-align: center;
    }
}
