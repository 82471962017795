
.product-service {
  box-sizing: border-box;
  height: 100%;
  .title-box {
    margin: 20px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    .title {
      display: flex;
      align-items: center;
      .dot {
        width: 4px;
        height: 16px;
        background: #2dc079;
        border-radius: 2px;
        margin-right: 6px;
      }
    }
  }
  ::v-deep .el-checkbox__label {
    position: absolute;
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ::v-deep .el-table__expand-icon {
    display: none;
  }
}
