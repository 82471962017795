
.community-promotion{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    .title-info {
        margin-top: 34px;
        .optimize-title {
            font-size: 16px;
            color: #333;
            position: relative;
            padding: 0 0 0 16px;
            margin-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 16px;
                background: #2DC079;
                left: 0;
                top: 4px;
            }
        }
        .item {
            margin-bottom: 16px;
            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }
        }
        ::v-deep .el-textarea__inner{
            height: 120px;
            resize: none
        }
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }
    .dialog-footer{
        text-align: center;
        margin-top: 30px;
    }
}
