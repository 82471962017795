
.distribution-management{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    .title-info {
        margin-top: 34px;
        .optimize-title {
            font-size: 16px;
            color: #333;
            position: relative;
            padding: 0 0 0 16px;
            margin-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 16px;
                background: #2DC079;
                left: 0;
                top: 4px;
            }
        }
        .optimize-title-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .optimize-title01 {
                font-size: 16px;
                color: #333;
                position: relative;
                padding-left: 16px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
        }
        .item {
            margin-bottom: 16px;
            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }
        }
        .item {
            margin-bottom: 16px;
            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }
            .freight-box {
                border: 1px solid #eee;
                .freight-top {
                    background: #F6F6F6;
                    padding: 20px 47px;
                }
                .region-item {
                    display: flex;
                    span {
                        display: inline-block;
                        width: 40px;
                        color: #2461EF;
                        padding-left: 20px;
                        align-self: center;
                        cursor: pointer;
                    }
                }
                .add-freight {
                    cursor: pointer;
                    padding: 20px;
                    color: #2461EF;
                }
            }
            .freight-title {
                margin-bottom: 15px;
            }
            .freight-score {
                margin-top: 12px;
            }
        }
    }
    .pop-up{
        height:300px;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .area-box {
            display: flex;
            .area-list {
                display: flex;
                flex-wrap: wrap;
                padding-left: 45px;
                .checkbox-label {
                    width: 104px;
                    margin-bottom: 10px;
                    i {
                        cursor: pointer;
                    }
                }
            }
            .checkbox-group {
                margin: 10px 0;
                ::v-deep .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    /*width: 100px;*/
                    /*background-color: #fff;*/
                    /*box-shadow: 2px 2px 2px 3px #eee;*/
                    padding-left: 10px;
                }
                /*::v-deep .el-checkbox {*/
                /*    width: 58px;*/
                /*}*/
            }
        }
    }
    .dialog-footer {
        margin-top: 20px;
        text-align: center;
    }
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }
}

