
.mobile-release {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    .goods-release-content {
        .key-item {
        }
        .item {
            margin-bottom: 16px;
            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }
            .freight-box {
                border: 1px solid #eee;
                .freight-top {
                    background: #F6F6F6;
                    padding: 20px 47px;
                }
                .region-item {
                    display: flex;
                    span {
                        display: inline-block;
                        width: 40px;
                        color: #2461EF;
                        padding-left: 20px;
                        align-self: center;
                        cursor: pointer;
                    }
                }
                .add-freight {
                    cursor: pointer;
                    padding: 20px;
                    color: #2461EF;
                }
            }
            .freight-title {
                margin-bottom: 15px;
            }
            .freight-score {
                margin-top: 12px;
            }
            .material-upload-area {
                display: flex;
                align-items: center;
                .el-upload-tip {
                    color: #999;
                    padding-left: 10px;
                }
                ::v-deep .el-upload-list {
                    display: flex;
                    align-items: center;
                }
            }
            ::v-deep .el-upload-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            ::v-deep .el-upload-list__item {
                width: 300px;
                padding-left: 10px;
            }
        }
        .title-info {
            margin-top: 34px;
            .optimize-title {
                font-size: 16px;
                color: #333;
                position: relative;
                padding: 0 0 0 16px;
                margin-bottom: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2DC079;
                    left: 0;
                    top: 4px;
                }
            }
            .optimize-title-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .optimize-title01 {
                    font-size: 16px;
                    color: #333;
                    position: relative;
                    padding-left: 16px;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 16px;
                        background: #2DC079;
                        left: 0;
                        top: 4px;
                    }
                }
            }
            .upload-btn {
                position: relative;
                display: flex;
                align-items: center;
                .btn {
                    width: 88px;
                    height: 38px;
                    background: #E7F6EF;
                    border: 1px solid #2DC079;
                    border-radius: 4px;
                    line-height: 38px;
                    text-align: center;
                    color: #2DC079;
                }
                input {
                    width: 88px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                    opacity: 0;
                }
                .text {
                    color: #999999;
                    font-size: 14px;
                    margin-left: 15px;
                }
            }
            .upload-file-list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                .file-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    padding: 10px;
                    box-sizing: border-box;
                    color: #333333;
                    font-size: 14px;
                    margin: 0 20px 15px 0;
                    &:hover {
                        cursor: pointer;
                        background-color: #F5F7FA;
                        .del-icon {
                            display: block;
                        }
                        .finish-icon {
                            display: none;
                        }
                    }
                    .file-name {
                        display: inline-block;
                        width: 1%;
                        flex: 1;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                    i {
                        color: #2461EF;
                        margin:  0 10px;
                    }
                    .finish-icon {
                        color: #2DC079;
                        margin: 0 10px;
                    }
                    .del-icon {
                        display: none;
                        color: #FF0000;
                        margin: 0 10px;
                    }
                    .el-progress {
                        margin-left: 20px;
                        width: 160px;
                    }
                }
            }
        }
        ::v-deep.customTable th:first-child {
            border-left: none;
        }
        ::v-deep.customTable td:first-child {
            border-left: none;
        }
    }
    ::v-deep .el-input-number {
        .el-input__inner {
            text-align: left;
        }
    }
    .goods-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .allot-class, .allot-class-two {
        &.allot-class-two .el-cascader-menu {
            width: 50%;

            &:last-of-type {
                border-right: none;
            }
        }
        &.allot-class .el-cascader-menu {
            width: calc(100% / 3);
            &:nth-child(3) {
                border-right: none;
            }
        }
        .el-cascader-menu {
            border-right: 1px solid #E4E7ED;
            .el-cascader-menu__wrap {
                height: 286px;
                .el-cascader-menu__list {
                    .el-cascader-node {
                        &:hover {
                            background: #E7F6EF;
                        }
                    }
                }
            }
        }
    }
    .pop-up{
        height:300px;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .area-box {
            display: flex;
            .area-list {
                display: flex;
                flex-wrap: wrap;
                padding-left: 45px;
                .checkbox-label {
                    width: 104px;
                    margin-bottom: 10px;
                    i {
                        cursor: pointer;
                    }
                }
            }
            .checkbox-group {
                margin: 10px 0;
                ::v-deep .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    /*width: 100px;*/
                    /*background-color: #fff;*/
                    /*box-shadow: 2px 2px 2px 3px #eee;*/
                    padding-left: 10px;
                }
                /*::v-deep .el-checkbox {*/
                /*    width: 58px;*/
                /*}*/
            }
        }
    }
    .class-content {
        height: 287px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        .class-cascader {
            height: 100%;
            border: none;
        }
    }
    .dialog-footer {
        margin-top: 20px;
        text-align: center;
    }
}

