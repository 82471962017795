
.main-box {
    &, & * {
        box-sizing: border-box;
    }
    #file {
        display: none;
    }
    .title-block {
        .title-txt {
            font-size: 16px;
            color: #333333;
            position: relative;
            padding-left: 14px;
            &::before {
                display: inline-block;
                position: absolute;
                width: 4px;
                height: 16px;
                top: 2px;
                left: 0px;
                content: "";
                background-color: #2DC079;
                border-radius: 2px;
            }
        }
        .upload-content {
            margin-top: 26px;
        }
        .file-list-preview {
            margin-top: 16px;
            .file-item {
                height: 40px;
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;
                padding: 0 20px;
                &:hover {
                    background-color: #F5F7FA;
                }
                .file-icon {
                    i {
                        color: #2461EF;
                    }
                }
                .file-name {
                    margin: 0  10px 0 12px;
                    font-size: 14px;
                    color: #333333;
                }
                .process-bar {
                    width: 200px;
                }
                .action-icon {
                    cursor: pointer;
                    margin-left: 12px;
                    i {
                        font-size: 16px;
                    }
                    .icon-ok {
                        color: #2DC079;
                    }
                    .icon-fail {
                        color: #ff0000;
                    }
                }
                .download-item {
                    margin-left: 12px;
                }
            }
        }
    }
}
