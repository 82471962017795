
.customer-content {
    margin-top: 20px;
    font-size: 16px;
    color: #333333;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}

.item-input {
    &.max {
        width: 320px;
    }

    &.mini {
        width: 140px;
    }
}

.content-item {

    .content-title {
        position: relative;
        padding-left: 10px;
        margin-bottom: 22px;

        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background-color: #2DC079;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 3px;
        }
    }

    .item-content {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .item-title {
            width: 150px;
            text-align: right;
            margin-right: 10px;
        }

        .divide {
            width: 24px;
            height: 2px;
            background: #D2D2D2;
            margin: 0 18px;
        }

        .file-upload-btn {
            display: none;
        }

        .upload-text {
            margin-left: 11px;
            font-size: 14px;
            color: #999999;
        }

        .upload-file-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;

            .file-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 40px;
                padding: 10px;
                box-sizing: border-box;
                color: #333333;
                font-size: 14px;
                margin: 0 20px 15px 0;

                &:hover {
                    cursor: pointer;
                    background-color: #F5F7FA;

                    .del-icon {
                        display: block;
                    }

                    .finish-icon {
                        display: none;
                    }
                }

                .file-name {
                    display: inline-block;
                    width: 1%;
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .el-progress {
                    margin-left: 20px;
                    width: 160px;
                }

                i {
                    color: #2461EF;
                    margin: 0 10px 0 0;
                }

                .finish-icon {
                    color: #2DC079;
                    margin: 0 0 0 10px;
                }

                .del-icon {
                    display: none;
                    color: #FF0000;
                    margin: 0 0 0 10px;
                }
            }
        }
    }

}

.footer-wrapper {
    text-align: center;
    margin-top: 50px;
}
