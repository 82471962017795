
.client-info-table {
    margin-top: 25px;
}
.client-score {
    box-sizing: border-box;
    width: 100px;
    height: 40px;
    text-align: center;
    border: 1px solid #c0c4cc;
    border-radius: 4px;
    outline: none;
}
.line-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .left {
        width: 66px;
        margin-right: 10px;
        color: #333;
        font-size: 16px;
        text-align: right;
    }
}
.second-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: "";
        width: 4px;
        height: 16px;
        background: #2dc079;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}
.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
.goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
        width: 80px;
        height: 80px;
        display: flex;
        background: #f6f6f6;
        img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .goods-name {
        width: 1%;
        flex: 1;
        margin-left: 10px;
        font-size: 16px;
    }
}
.prompt-text {
    margin-left: 10px;
    font-size: 16px;
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #666;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
