
.line-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    .left {
        width: 100px;
        margin-right: 10px;
        color: #333;
        font-size: 16px;
        text-align: right;
    }
}
.second-title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
        content: '';
        width: 4px;
        height: 16px;
        background: #2DC079;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 2px;
    }
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #666;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
