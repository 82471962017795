
.procurement-management{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    .title-info {
        margin-top: 34px;
        .optimize-title {
            font-size: 16px;
            color: #333;
            position: relative;
            padding: 0 0 0 16px;
            margin-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 16px;
                background: #2DC079;
                left: 0;
                top: 4px;
            }
        }
        .item {
            margin-bottom: 16px;
            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }
        }
    }
    .number{
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }
    .goods-img-box{
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        position: relative;
        margin: 10px 0;
        .src-box{
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 1px solid #EEEEEE;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        .clear-good-img {
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: -9px;
            right: -9px;
            z-index: 1;
            font-size: 18px;
            color: #aaa;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #ff0000;
            }
        }

    }
    .logo-uploader {
        width: 200px;
        height: 200px;
        border: 1px solid #ddd;
        background: #DFDFE5;
        margin: 10px 0;

        ::v-deep .el-upload {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .goods-uploader-icon {
            font-size: 32px;
            color: #A0A0A0;
        }
    }
    .school-prompt{
        color: #999;
        font-size: 14px;
    }
    ::v-deep .step-form {
        /*padding-left: 30px;*/
        .office-title {
            margin-bottom: 40px;
            .el-form-item__label {
                font-size: 16px;
                font-weight: 500;
                color: #13131B;
            }
        }
        .form-title {
            margin-top: 20px;
            font-size: 16px;
            display: flex;
            align-items: center;
            line-height: 1;
            padding-bottom: 12px;
        }
        .form-title-prompt {
            font-size: 14px;
            color: #999;
            margin-left: 10px;
        }

        .form-content {
            /*padding-left: 45px;*/
            & > .el-form-item {
                margin-right: 110px;
                width: 200px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &.goods-info-wrapper {
                display: flex;
                .item {
                    .line-title {
                        line-height: 41px;
                        width: 70px;
                        margin-bottom: 22px;
                        text-align: right;
                        position: relative;
                        margin-right: 10px;
                        &.line-title-first {
                            width: 160px;
                            line-height: 1;
                            font-size: 16px;
                            color: #13131B;
                            height: 16px;
                            text-align: center;
                            margin: 30px 60px 20px 0;
                        }
                    }
                }
            }
        }
        .symbol-style {
            margin-left: 6px;
        }
        .wavy-line {
            line-height: 40px;
            margin: 0 7px 0 6px;
        }
        .mb40 {
            margin-bottom: 40px !important;
        }
        .mb18 {
            margin-bottom: 18px !important;

        }
    }
    .dialog-footer {
        margin-top: 20px;
        text-align: center;
    }
}
