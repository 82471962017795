
.demo-ruleForm {
    ::v-deep {
        .el-form-item__label {
            color: #333;
            font-size: 16px;
        }
    }
}
.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
