
.content-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
        }
    }

    .header {
        padding: 0 20px;
    }

    .practice-content {
        background-color: #fff;
        padding: 20px;

        .content-item {
            .item {
                margin-bottom: 16px;

                .text {
                    display: inline-block;
                    width: 66px;
                    text-align: right;
                    font-size: 16px;
                    color: #333333;
                    margin-right: 10px;
                }

                .name-input {
                    width: 420px;

                    ::v-deep .el-input__inner {
                        border-radius: 2px;
                        text-align: left;
                    }
                }

                .practice-select {
                    width: 200px;
                    margin-right: 10px;

                    ::v-deep .el-input__inner {
                        border-radius: 2px;
                    }
                }

                .practice-cascader {
                    width: 420px;

                    ::v-deep .el-input__inner {
                        border-radius: 2px;
                    }
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                font-size: 16px;

                .left-icon {
                    display: inline-block;
                    width: 4px;
                    height: 16px;
                    background-color: #2DC079;
                    margin-right: 6px;
                    border-radius: 2px;
                }
            }
        }
    }

    .content-table {
        .text {
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            background: #E7F6EF;
            border-radius: 50%;
            text-align: center;
        }
    }
}

.add-task-dialog {
    .demo-ruleForm {
        .el-form-item {
            display: flex;
            align-items: center;

            ::v-deep .el-form-item__label {
                margin-bottom: 0;
            }

            ::v-deep .el-form-item__content {
                width: 1%;
                flex: 1;
                margin-left: 10px !important;

                .el-input-number {
                    width: 100%;
                }

                .el-input-number.is-without-controls .el-input__inner {
                    text-align: left;
                }
            }
        }
    }

    .dialog-footer {
        text-align: center;
    }
}

::v-deep .tox-tinymce {
    flex: 1;
    width: 1%;
}
