
.mobile-marketing-operations-content {
    margin: 20px 0;
    .goods-item {
        display: flex;
        flex-direction: column;
        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            font-size: 16px;
            .left-icon {
                display: inline-block;
                width: 4px;
                height: 16px;
                background-color: #2DC079;
                margin-right: 6px;
                border-radius: 2px;
            }
        }
        .hot-goods {
            justify-content: flex-start;
            .distribution-goods {
                margin-left: 20px;
            }
        }
        .content-item {
            .item {
                margin-bottom: 16px;
                .text {
                    display: inline-block;
                    width: 100px;
                    text-align: right;
                    font-size: 16px;
                    color: #333333;
                    margin-right: 10px;
                }
                .name-input {
                    width: 360px;
                    ::v-deep .el-input__inner {
                        border-radius: 2px;
                        text-align: left;
                    }
                }
                .practice-select {
                    width: 360px;
                    margin-right: 10px;
                    ::v-deep .el-input__inner {
                        border-radius: 2px;
                    }
                }
                .practice-cascader {
                    width: 420px;
                    ::v-deep .el-input__inner {
                        border-radius: 2px;
                    }
                }
            }
            .has-sore {
                display: flex;
                .has-btn {
                    display: flex;
                    align-items: center;
                    .name-input {
                        border: 1px solid #DCDFE6;
                        color: #606266;
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        padding: 0 15px;
                        margin-right: 16px;
                    }
                    .none-data {
                        color: #b1b4c1d6;
                    }
                }
                .placement-item {
                    display: flex;
                    align-items: center;
                    .placement-radio-group {
                        ::v-deep {
                            .el-radio {
                                width: 136px;
                            }
                        }
                    }
                }
            }
            .toutiao-item {
                .placement-radio-group {
                    ::v-deep {
                        .el-radio {
                            width: 96px !important;
                        }
                    }
                }
            }
            .promotion-plan-item {
                .placement-radio-group {
                    ::v-deep {
                        .el-radio {
                            width: 131px !important;
                        }
                    }
                }
            }
            .goods-put {
                .item {
                    display: flex;
                    align-items: center;
                }
            }
            .goods-title {
                .name-input {
                    width: 474px;
                }
            }
        }
        .upload-btn {
            position: relative;
            display: flex;
            align-items: center;
            .btn {
                width: 88px;
                height: 38px;
                background: #E7F6EF;
                border: 1px solid #2DC079;
                border-radius: 4px;
                line-height: 38px;
                text-align: center;
                color: #2DC079;
            }
            input {
                width: 88px;
                height: 40px;
                position: absolute;
                top: 0;
                cursor: pointer;
                opacity: 0;
            }
            .text {
                color: #999999;
                font-size: 14px;
                margin-left: 15px;
            }
        }
    }
}
.placement-table {
    .text {
        flex: 1;
        width: 1%;
        text-align: left;
    }
    .one-click-creativity {
        width: 74px;
        height: 24px;
        background-color: #2D98F4;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        margin-left: 20px;
    }
    ::v-deep {
        td:first-child > .cell {
            padding-left: 0;
            padding-right: 0;
            .el-radio {
                .el-radio__label {
                    display: none;
                }
            }
        }
        .ad-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 150px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
.area-list {
    width: 600px;
    margin-left: 134px;
    .el-checkbox-group {
        .goods-checkbox {
            margin: 0 50px 50px 0;
            width: 100px;
        }
    }
    .all-checkbox {
        position: relative;
        top: -45px;
        ::v-deep .el-checkbox__label {
            font-size: 16px;
        }
    }
}
.tip {
    color: #999999;
    margin-left: 110px;
    margin-bottom: 10px;
}
.upload-file-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        color: #333333;
        font-size: 14px;
        margin: 0 20px 15px 0;

        &:hover {
            cursor: pointer;
            background-color: #F5F7FA;

            .del-icon {
                display: block;
            }

            .finish-icon {
                display: none;
            }
        }

        .file-name {
            display: inline-block;
            width: 1%;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-progress {
            margin-left: 20px;
            width: 160px;
        }

        i {
            color: #2461EF;
            margin: 0 10px 0 0;
        }

        .finish-icon {
            color: #2DC079;
            margin: 0 0 0 10px;
        }

        .del-icon {
            display: none;
            color: #FF0000;
            margin: 0 0 0 10px;
        }
    }
}
.file-upload-btn {
    display: none;
}
.upload-text {
    font-size: 14px;
    color: #999;
    margin-left: 10px;
}
.time_table  ::v-deep {
    th:first-child > .cell {
        padding-left: 10px;
        border-left: none;
        border-top: none;
    }
    td:first-child {
        border-left: none;
    }
    .el-table__body-wrapper .el-table__body td {
        &:first-child > .cell {
            padding-left: 35px !important;
        }
        padding: 0;
        height: 30px;
        .cell {
            padding: 0;
        }
    }
    thead.is-group {
        tr {
            th {
                &:last-child {
                    border-left: none;
                    border-right: none;
                }
            }
        }
        th {
            border-top: none;
        }
        th:last-child {
            border-left: none;
            border-right: none;
        }
    }
    tbody tr:hover>td {
        .table-cell {
            color: #F5F7FA;
        }
        .is-click {
            color: #00A0E9;
        }
    }
}
.table-cell {
    height: 30px;
    cursor: pointer;
    color: #fff;
}
.is-click {
    background-color: #00A0E9;
    height: 30px;
    color: #00A0E9;
}
.daily-limit-tip {
    height: 114px;
    background-color: #F8F8F8;
    flex-direction: column;
    justify-content: center;
    color: #333;
    font-size: 12px;
    padding: 0 20px;
    margin-bottom: 20px;
    .daily-limit-tip-item {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.quota-input {
    display: flex;
    position: absolute;
    left: 400px;
    .quota-yuan {
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-size: 16px;
        .quota-yuan-tip {
            color: #666666;
            font-size: 14px;
            margin-left: 10px;
        }
    }
}
