
.line-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  .left {
    width: 100px;
    margin-right: 10px;
    color: #333;
    font-size: 16px;
    text-align: right;
  }
}
.el-table {
  border: 1px solid #e5e5e5;
}
.addbutton {
  margin: 20px 0;
}
.title {
  margin-bottom: 15px;
  font-size: 16px;

  .left-icon {
    display: inline-block;
    width: 4px;
    height: 16px;
    background-color: #2dc079;
    margin-right: 6px;
    border-radius: 2px;
  }
}
.uploadDown {
  margin-top: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  .download {
    color: #2461ef;
    margin-left: 40px;
  }
}
.upload-btn {
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  align-items: center;
  .btn {
    width: 88px;
    height: 38px;
    background: #e7f6ef;
    border: 1px solid #2dc079;
    border-radius: 4px;
    line-height: 38px;
    text-align: center;
    color: #2dc079;
  }
  input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
  .text {
    color: #999999;
    font-size: 14px;
    margin-left: 15px;
  }
  ::v-deep .input-error {
    &.isError {
      .el-input__inner {
        border-color: #fd4446;
      }
    }
  }
}
.el-date-editor.el-input {
  width: 180px !important;
}
.pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
::v-deep .imgBox{
  width: 300px;
  display: flex;
}

